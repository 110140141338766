<template>
  <div class="application">
    <!-- <div class="application-banner">
      <img src="http://0vr1.com/video/2021-06-21/2021/FrontPic/img/banner.fd76be7b.png" v-if="$isMobile()" />
      <img src="http://0vr1.com/video/2021-06-21/2021/FrontPic/img/banner.f8493f10.png" v-else>
      <div class="application-banner-title">
        <h3>VR教-企业范例</h3>
        <span>VR LABORATORY CONSTRUCTION</span>
      </div>
    </div> -->
    <div class="application-breadcrumb">
      当前位置:<span @click="$router.push({ path: '/' })">首页</span> -> <span
        @click="$router.push({ path: 'vr-taught' })">VR教企业范例</span>
    </div>
    <div class="application-content">
      <div class="application-tab">
        <div :class="{ 'application-tab-item': true, 'application-tab-item--active': appTabItem === '虚仿基地建设案例' }"
          @click="change('虚仿基地建设案例')">虚仿基地建设案例</div>
        <div :class="{ 'application-tab-item': true, 'application-tab-item--active': appTabItem === '虚仿课程建设案例' }"
          @click="change('虚仿课程建设案例')">虚仿课程建设案例</div>
        <div :class="{ 'application-tab-item': true, 'application-tab-item--active': appTabItem === '行业应用案例' }"
          @click="change('行业应用案例')">行业应用案例</div>
      </div>
      <div style="height:20px;"></div>
      <template v-if="appTabItem === '虚仿基地建设案例'">
        <empty v-if="total === 0" :message="'暂无案例，请选择其他课程或查看其他案例'" />
        <div v-else class="row" style="min-height: 212px;">
          <div class="application-item col-sm-3 col-12" v-for="(item) in applications" :key="item.id">
            <vr-taught-item :info="item" :type="appTabItem"></vr-taught-item>
          </div>
        </div>
      </template>
      <template v-if="[
        '虚仿课程建设案例',
        '行业应用案例',
      ].includes(appTabItem)
        ">
        <div class="row" style="min-height: 212px;">
          <div class="application-itemTab">
            <div
              :class="{ 'application-itemTab-item col-12': true, 'application-itemTab-item--active': (appTabItem === '行业应用案例' ? industryTabItem.dictLabel : majorTabItem.dictLabel) === item.dictLabel }"
              v-for="(item, index) in appTabItem === '行业应用案例' ? industryList : majorList"
              :key="appTabItem === '行业应用案例' ? index + '行业' : index + '专业'" @click="changeTabItem(item, appTabItem)">{{
                item.dictLabel }}</div>
          </div>
          <div class="col offset-1">
            <empty v-if="total === 0" :message="'暂无案例，请选择其他课程或查看其他案例'" />
            <div v-else class="row">
              <div class="application-item col-sm-6 col-12" v-for="(item) in applications" :key="item.id">
                <vr-taught-item2 :info="item" :type="appTabItem"></vr-taught-item2>
              </div>
            </div>
          </div>
        </div>
      </template>
      <page :total="total" @pageChange="getList"></page>
    </div>
  </div>
</template>
<script>
import vrTaughtItem from './vr-taught-item'
import vrTaughtItem2 from './vr-taught-item2'
import page from '@components/page/page'
import empty from '@components/empty/empty'
export default {
  name: 'VRTaught',
  components: {
    vrTaughtItem,
    vrTaughtItem2,
    page,
    empty
  },
  data() {
    return {
      appTabItem: '虚仿基地建设案例',
      applications: [],
      total: 0,
      majorList: [],
      majorTabItem: '',
      industryList: [],
      industryTabItem: ''
    }
  },
  created() {
    this.getList(1)
    this.getTabs()
  },
  methods: {
    change(item) {
      this.appTabItem = item
      this.getList(1)
    },
    changeTabItem(item, appTabItem) {
      if (appTabItem === '行业应用案例') {
        this.industryTabItem = item
      } else {
        this.majorTabItem = item
      }
      this.getList(1)
    },
    toDetail(id) {
      this.$router.push({
        path: '/vr-taught-detail',
        query: {
          id
        }
      })
    },
    getList(pageNum) {
      let url = ''
      if (this.appTabItem === '虚仿基地建设案例') {
        url = '/vrbaseconstrcase/list'
      }
      if (this.appTabItem === '虚仿课程建设案例') {
        url = '/vrcourseconstrcase/list'
      }
      if (this.appTabItem === '行业应用案例') {
        url = '/vrindustryapplibase/list'
      }
      if (pageNum === 1) {
        this.applications = []
      }
      this.$http({
        url,
        params: {
          pageNum,
          pageSize: 8,
          major: this.appTabItem === '虚仿课程建设案例' ? this.majorTabItem.dictValue : undefined,
          industry: this.appTabItem === '行业应用案例' ? this.industryTabItem.dictValue : undefined,
        }
      }).then(res => {
        this.applications = res.rows || []
        this.total = res.total
      })
    },
    getTabs() {
      this.$http2({
        url: "/system/dict/data/type/industry_category",
      }).then(res => {
        this.industryList = res.data
        this.industryTabItem = res.data[0]
      })
      this.$http2({
        url: "/system/dict/data/type/major_category",
      }).then(res => {
        this.majorList = res.data
        this.majorTabItem = res.data[0]
      })
    }
  }
}
</script>
<style scoped lang="scss">
.application {
  position: relative;

  &-item {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  &-banner {
    width: 100%;
    position: relative;
    width: 100%;

    img {
      width: 100%;
    }

    &-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      text-align: center;

      h3 {
        font-size: 22px;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.8;
      }

      span {
        font-size: 1em;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        width: 400px;
        display: inline-block;
      }
    }
  }

  &-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 30px 30px 30px;
    background: #fff;
    margin-bottom: 40px;
  }

  &-header {
    margin-top: 60px;
    align-items: center;
  }

  &-breadcrumb {
    max-width: 1200px;
    margin: 0 auto;
    padding: 12px 0;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    text-align: left;
    cursor: pointer;
    font-family: Microsoft YaHei;
  }

  &-itemTab {
    width: 150px;

    &-item {
      padding: 5px 16px;
      font-size: 16px;
      border: 1px solid #eee;
      color: #666666;
      cursor: pointer;
      margin-top: 10px;
      display: inline-block;
      font-family: Microsoft YaHei;
    }

    &-item--active {
      background: #2870b6;
      color: #fff;
      border: 0;
    }
  }

  &-tab {

    //display: flex;
    &-item {
      padding: 5px 16px;
      font-size: 16px;
      border: 1px solid #eee;
      color: #666666;
      cursor: pointer;
      margin-top: 10px;
      display: inline-block;
      font-family: Microsoft YaHei;
      margin-right: -1px;
      margin-bottom: -1px;
    }

    &-item--active {
      background: #2870b6;
      color: #fff;
      border: 0;
    }
  }
}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
</style>